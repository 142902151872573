<template>
  <v-dialog v-model="dialog" max-width="400px">
    <v-card>
      <v-card-title>Atendimento Online</v-card-title>
      <v-divider></v-divider>
      <!-- <br />
      <v-card-title primary-title class="text-center">
        <v-row>
          <v-col>
            <v-avatar size="60px" color="#E6F2E6">
              <app-icon size="40px" color="success">videocam</app-icon>
            </v-avatar>
          </v-col>
        </v-row>
      </v-card-title>
      <br /> -->
      <v-card-text class="mb-4">
        <v-row class="my-6">
          <v-col class="text-center">
            <v-avatar size="70px" color="#EDEAFC">
              <app-icon size="40px" color="primary">videocam</app-icon>
            </v-avatar>
            <div class="mt-4">
              <h4>{{ session.customer.name }}</h4>
            </div>
            <div class="mt-2">
              <template v-if="session.video_call_link">
                <span class="text--secondary">{{ callLink }}</span>
              </template>
              <template v-else>
                <span class="text--secondary">
                  Video Chamada do Psicoplanner
                </span>
              </template>
            </div>
            <!-- <div class="mt-4 d-flex justify-space-around" style="width:100%">
              <div>
                <v-btn class="mx-4" fab depressed small color="grey lighten-2">
                  <app-icon>
                    content_copy
                  </app-icon>
                </v-btn>
                <div>
                  Copiar
                </div>
              </div>
              <div>
                <v-btn class="mx-4" fab depressed small color="grey lighten-2">
                  <app-icon>
                    share
                  </app-icon>
                </v-btn>
                <div>
                  Compartilhar
                </div>
              </div>
            </div> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="handleOpenVideoCall()" block color="primary">
              <app-icon class="mr-2">video_call</app-icon>
              Iniciar
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="session.customer.phone_wa">
          <v-col>
            <v-btn @click="handleSendLinkToWhatsapp()" :disabled="linkSent" outlined block color="primary">
              <v-icon class="mr-2">mdi-whatsapp</v-icon>
              {{ linkSentText }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="shareLinkSupported">
          <v-col>
            <v-btn @click="handleShareLink()" outlined block color="primary">
              <app-icon class="mr-2">share</app-icon>
              Compartilhar Link
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="clipboardSupported">
          <v-col>
            <v-btn @click="handleCopyLink()" outlined block color="primary">
              <app-icon class="mr-2">content_copy</app-icon>
              Copiar Link
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <app-back-btn @click="dialog = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      shareLinkSupported: null,
      session: {
        customer: {},
      },
      linkSent: false,
    };
  },
  created() {
    this.shareLinkSupported = "share" in navigator;
  },

  computed: {
    linkSentText() {
      return this.linkSent === true
        ? "Notificação enviada"
        : "Enviar link por WhatsApp";
    },
    clipboardSupported() {
      return navigator.clipboard;
    },

    isCustomLink() {
      return this.session.video_call_link !== null;
    },

    callLink() {
      if (this.isCustomLink) {
        return this.session.video_call_link;
      }

      return "https://meu.psicoplanner.com.br/api/videochamada";
    },

    professionalCallLink() {
      if (!this.isCustomLink) {
        return `${this.callLink}/profissional/${this.session.id}/${this.session.uuid}`;
      }
      return this.callLink;
    },
    customerCallLink() {
      if (!this.isCustomLink) {
        return `${this.callLink}/${this.session.id}/${this.session.uuid}`;
      }
      return this.callLink;
    },
  },

  methods: {
    open(session) {
      this.session = JSON.parse(JSON.stringify(session));
      this.dialog = true;
      this.linkSent = false;
    },

    handleOpenVideoCall() {
      window.open(this.professionalCallLink, "_blank");
    },

    handleSendLinkToWhatsapp() {
      this.$loading.start();
      this.$http
        .$post("sessions/send-link-video-call", {
          session_id: this.session.id,
        })
        .then((response) => {
          this.$snackbar({
            message: "Notificação  por WhatsApp enviada !",
            color: "success",
            timeout: 1000,
            showClose: false,
          });
          this.linkSent = true;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleShareLink() {
      navigator.share({
        title: "Compartilhar Link",
        text: "Compartilhar Link",
        url: this.customerCallLink,
      });
    },

    handleCopyLink() {
      navigator.clipboard
        .writeText(this.customerCallLink)
        .then((response) => {
          this.$snackbar({
            message: "Link copiado com sucesso!",
            color: "success",
            timeout: 1000,
            showClose: false,
          });
        })
        .catch((error) => { });
    },
  },
};
</script>

<style></style>
